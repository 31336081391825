import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { sp } from "../media"

const GlobalNav = () => {
  const Wrapper = styled.nav`
    margin: 0 auto;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(40px);
    border-radius: 20px;
    padding: 20px 0;
    ${sp`
      width: 100%;
      backdrop-filter: blur(4rem);
      border-radius: 2rem;
      padding: 2rem 0;
    `}
  `
  const Items = styled.ol`
    display: flex;
    list-style: none;
    align-items: flex-end;
    justify-content: center;
  `
  const Item = styled.li`
    width: auto;
    font-size: 24px;
    line-height: 1;
    color: #111111;
    padding: 0 10px;
    ${sp`
      font-size: 1.8rem;
      font-weight: 600;
      padding: 0 0.8rem;
    `}
  `
  const Logo = styled.li`
    width: 250px;
    padding: 0 0px;
    margin: 0 10px 0 0;
    display: flex;
    align-content: center;
    > a{
      display: inherit;
    }
    ${sp`
      width: 18rem;
      margin: 0 1rem 0 0;
    `}
  `
  return (
    <Wrapper>
      <Items>
        <Logo>
          <Link to="/"><img src="/img/logo_typo_side.svg" alt="Office Hxn" /></Link>
        </Logo>
        <Item><Link to="/">Home</Link></Item>
        <Item><Link to="/about">About</Link></Item>
        <Item><Link to="/works">Works</Link></Item>
        <Item><Link to="/contact">Contact</Link></Item>
      </Items>
    </Wrapper>
  )
}

export default GlobalNav