import React from 'react';
import MainFilter from './components/MainFilter';
import styled from 'styled-components';
import MainBackGround from "./components/MainBackground"
import RouterConfig from './RouterConfig';
import MainWindow from './components/MainWindow';
import GlobalNav from './components/GlobalNav';
import Content from './components/Content';
import { BrowserRouter } from 'react-router-dom';
import { sp } from './media';

const App = () => {
  const Body = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 40px 0;
    ${sp`
      padding: 4rem 0;
    `}
  `
  return (
    <div className="App">
      <Body>
        <BrowserRouter>
          <Content>
            <GlobalNav />
            <MainWindow><RouterConfig /></MainWindow>
          </Content>
        </BrowserRouter>
        <MainFilter />
        <MainBackGround />
      </Body>
    </div>
  );
}

export default App;
