import styled from "styled-components"

const Icon = styled.span`
  font-size: 1em;
  line-height: inherit;
  display: inline-flex;
  align-self: center;
  color: #111111;
  transition: 500ms
`
export default Icon