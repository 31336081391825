import React from 'react';
import styled from "styled-components"
import Inner from '../components/Inner';
import PageTitle from '../components/PageTitle';
import Text from "../components/Text"
import Icon from "../components/Icon"
import Button from "../components/Button"
import { AiFillInstagram } from "react-icons/ai"
import { sp } from "../media"

const About = () => {
  const Profile = styled(Inner)`
    margin: 30px auto 0;
    padding: 0 0 0 20px;
    ${sp`
      margin: 3rem auto 0;
      padding: 0 0 0 3rem;
    `}
  `
  const Title2 = styled.h2`
    font-size: 32px;
    line-height: 1.6;
    ${sp`
      font-size: 3.2rem;
    `}
  `
  const ProfileTop = styled.div`
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto 0;
    ${sp`
      width: 45rem;
      margin: 2rem auto 0;
    `}
  `
  const FacePhoto = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid #111111;
    overflow: hidden;
    margin: 0 30px 0 0;
    ${sp`
      width: 12rem;
      height: 12rem;
      border: 0.2rem solid #111111;
      margin: 0 3rem 0 0;
    `}
  `
  const Name = styled.div`
    font-size: 26px;
    line-height: 1.6;
    color: #111111;
    ${sp`
      font-size: 2.6rem;
      font-weight: 600;
    `}
  `
  const ProfileText = styled(Text)`
    width: 400px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin: 30px auto 0;
    ${sp`
      width: 45rem;
      margin: 3rem;
    `}
  `
  const Links = styled(Inner)`
    margin: 30px auto 0;
    padding: 0 0 0 20px;
    ${sp`
      margin: 3rem auto 0;
      padding: 0 0 0 3rem;
    `}
  `
  const Items = styled.ol`
  width: 200px;
  list-style: none;
  ${sp`
    width: 20rem;
  `}
`
  const Item = styled.li`
    font-size: 20px;
    line-height: 1.6;
    font-weight: 600;
    color: #111111;
    padding: 5px 0;
    ${sp`
      font-size: 2rem;
      padding: 0.5rem 0;
    `}
  `

  return (
    <Inner>
      <PageTitle>About</PageTitle>
      <Profile>
        <Title2>Profile</Title2>
        <ProfileTop>
          <FacePhoto><img src="/img/face.jpg" alt="face" /></FacePhoto>
          <Name>Hayato</Name>
        </ProfileTop>
        <ProfileText>I live in Japan and work as a freelancer producing websites, web applications, and videos.</ProfileText>
      </Profile>
      <Links>
        <Title2>Links</Title2>
        <Items>
          <Item>
            <a href="https://www.instagram.com/office_hxn/" target="_blank" rel="noreferrer">
              <Button>
                <Icon><AiFillInstagram /></Icon>&nbsp;Instagram
              </Button>
            </a>
          </Item>
        </Items>
      </Links>
    </Inner>
  )
}

export default About