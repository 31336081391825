import React from "react";
import { ReactNode } from "react";
import styled from "styled-components";
import { sp } from "../media"

type Props = {
  children: ReactNode
}

const Content = ({ children }: Props) => {
  const Content = styled.div`
    width: 768px;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
    ${sp`
      width: 95%;
    `}
  `
  return (
    <Content>
      {children}
    </Content>
  )

}

export default Content