import React from 'react';
import ReactDOM from 'react-dom/client';

import { createGlobalStyle } from 'styled-components';
import reset from "styled-reset";
import App from './App';
import { sp } from "./media"

const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
    font-family : inherit;
    font-size : 1em;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html, body{
    font-family: input-serif,serif;
    font-weight: 400;
    font-style: normal;
    ${sp`
      font-size: calc(100vw / 64);
    `}
  }
  img{
    width: 100%;
  }
  a{
  color: inherit;
  text-decoration: none;
  }
  a:visited{
    color: inherit;
  }
  button{
    color: inherit;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  .pc-only{
    display: block;
    ${sp`
      display: none;
    `}
  }
  .sp-only{
    display: none;
    ${sp`
      display: block;
    `}
  }
`


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);
