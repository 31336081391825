import React from 'react';
import styled from "styled-components"
import Inner from '../components/Inner';
import PageTitle from '../components/PageTitle';
import Text from "../components/Text"
import { sp } from '../media';

const Works = () => {
  const Category = styled.div`
    margin: 30px auto 0;
    padding: 0 0 0 20px;
    ${sp`
      margin: 3rem auto 0;
      padding: 0 0 0 3rem;
    `}
  `
  const CategoryTitle = styled.h2`
    font-size: 32px;
    line-height: 1.6;
    ${sp`
      font-size: 3.2rem;
    `}
  `
  const CategoryText = styled(Text)`
    margin: 10px 0 0 0;
    ${sp`
      margin: 1rem 0 0 0;
    `}
  `
  return (
    <Inner>
      <PageTitle>Works</PageTitle>
      <Category>
        <CategoryTitle>Web Site</ CategoryTitle>
        <CategoryText>Comming soon.</CategoryText>
      </Category>
      <Category>
        <CategoryTitle>Movie</ CategoryTitle>
        <CategoryText>Comming soon.</CategoryText>
      </Category>
      <Category>
        <CategoryTitle>App</ CategoryTitle>
        <CategoryText>Comming soon.</CategoryText>
      </Category>
      <Category>
        <CategoryTitle>Service</ CategoryTitle>
        <CategoryText>Comming soon.</CategoryText>
      </Category>
    </Inner>
  )
}

export default Works