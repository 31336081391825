import React, { ReactNode } from "react"
import styled from "styled-components"
import { sp } from "../media"

type Props = {
  children: ReactNode
}

const MainWindow = ({ children }: Props) => {
  const Window = styled.div`
    margin: 50px auto 0;
    width: 100%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 20px;
    padding: 50px 50px;
    ${sp`
      margin: 5rem auto 0;
      backdrop-filter: blur(4rem);
      border-radius: 2rem;
      padding: 3rem 3rem;
    `}
  `
  return (
    <Window>{children}</Window>
  )
}

export default MainWindow