import styled from "styled-components"
import { sp } from "../media"

const Button = styled.button`
  font-size: 1em;
  line-height: 1.6;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  transition: 500ms;
  &:hover{
    background-color: rgba(5, 5, 5, 0.8);
    color: #ffffff;
    transform: translateX(10px);
    > span{
      color: #ffffff;
    }
  }
  ${sp`
    padding: 0 1rem;
    border-radius: 0.5rem;
    &:hover{
      transform: translateX(0);
    }
  `}
`

export default Button