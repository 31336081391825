import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components"
import Text from "../components/Text"
import Inner from "../components/Inner"
import Icon from "../components/Icon"
import Button from "../components/Button"
import { MdInfo, MdOutlineWork, MdEmail } from "react-icons/md"
import { FaHandPointRight } from "react-icons/fa"
import { sp } from '../media';

const Home = () => {
  const Logo = styled.div`
    width: 430px;
    margin: 0 auto 30px;
    ${sp`
      width: 40rem;
      margin: 1rem auto 3rem;
    `}
  `
  const CenterText = styled(Text)`
    text-align: center;
  `
  const Menu = styled.div`
    width: 100%;
    margin: 30px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ${sp`
      margin: 2rem auto 0;
    `}
  `
  const Title = styled.h2`
    font-size: 30px;
    line-height: 1.6;
    font-weight: 600;
    text-align: right;
    margin: 0 50px 0 auto;
    > span{
      font-size: 36px;
      > svg{
        margin: 10px 0 0 10px;
      }
    }
    ${sp`
      font-size: 2.4rem;
      margin: 0 3rem 0 auto;
      > span{
        font-size: 2.4rem;
        > svg{
          margin: 0.5rem 0 0 1rem;
        }
      }
    `}
  `
  const Items = styled.ol`
    width: 200px;
    list-style: none;
    margin: 0 auto 0 0;
    ${sp`
      width: 20rem;
    `}
  `
  const Item = styled.li`
    font-size: 20px;
    line-height: 1.6;
    font-weight: 600;
    color: #111111;
    padding: 5px 0;
    ${sp`
      font-size: 2rem;
      padding: 0.5rem 0;
    `}
  `
  return (
    <Inner>
      <Logo><h1><img src='/img/logo_typo.svg' alt="Office HxN" /></h1></Logo>
      <CenterText>Self employment <br className='sp-only' />( engineer / movie editor / consultant )</CenterText>
      <Menu>
        <Title>Check it out!!<br /><Icon><FaHandPointRight /><FaHandPointRight /><FaHandPointRight /></Icon></Title>
        <Items>
          <Item>
            <Link to="/about">
              <Button><Icon><MdInfo /></Icon>&nbsp;About</Button>
            </Link>
          </Item>
          <Item>
            <Link to="/works">
              <Button><Icon><MdOutlineWork /></Icon>&nbsp;Works</Button>
            </Link>
          </Item>
          <Item>
            <Link to="/contact">
              <Button><Icon><MdEmail /></Icon>&nbsp;Contact</Button>
            </Link>
          </Item>
        </Items>
      </Menu>
    </Inner>
  )
}


export default Home