import React from 'react';
import styled from "styled-components"
import Inner from '../components/Inner';
import PageTitle from "../components/PageTitle"
import Text from "../components/Text"
import { sp } from '../media';

const NotFound = () => {
  const NotFoundText = styled(Text)`
    margin: 20px auto 0;
    ${sp`
      margin: 2rem auto 0;
    `}
  `
  return (
    <Inner>
      <PageTitle>Page Not Found</PageTitle>
      <NotFoundText>The requested URL was not found on this server.</NotFoundText>
    </Inner>
  )
}

export default NotFound