import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components"
import Inner from '../components/Inner';
import PageTitle from "../components/PageTitle"
import Text from '../components/Text';
import { sp } from '../media';

const Contact = () => {
  const ContactText = styled(Text)`
    margin: 30px 0 0 0;
    ${sp`
      margin: 3rem 0 0 0;
    `}
  `
  const ToSNS = styled.span`
    font-size: 1.2em;
    text-decoration: underline;
  `
  return (
    <Inner>
      <PageTitle>Contact</PageTitle>
      <ContactText>Sorry. We are preparing now.<br />
        Please contact us via <ToSNS><Link to="/about">SNS</Link></ToSNS>.
      </ContactText>
    </Inner>
  )
}

export default Contact