import styled from "styled-components"
import { sp } from "../media"

const PageTitle = styled.h1`
  font-size: 48px;
  color: #111111;
  font-weight: 600;
  ${sp`
    font-size: 4rem;
  `}
`

export default PageTitle