import styled from "styled-components"
import { sp } from "../media"

const Text = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #111111;
  text-align: justify;
  ${sp`
    font-size: 2rem;
  `}
`

export default Text