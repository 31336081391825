import React from 'react';
import { Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import About from "./pages/About"
import Works from './pages/Works';
import Contact from './pages/Contact';
import NotFound from "./pages/NotFound"
import usePageTracking from './hooks/useTracking';

const RouterConfig = () => {
  usePageTracking()
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/works" element={<Works />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default RouterConfig