import React from "react";
import styled from "styled-components";

const MainFilter = () => {
  const Filter = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  `
  return (
    <Filter>
    </Filter>
  )
}

export default MainFilter