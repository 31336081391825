import React from 'react';
import * as CANNON from "cannon"

import { Canvas, useFrame } from "react-three-fiber"
import { useCannon, Provider } from "../hooks/useCannon"

import styled from "styled-components"
import { MathUtils } from "three"
import { sp } from '../media';

type Props = {
  factor: number
  speed: number
  xFactor: number
  yFactor: number
  zFactor: number
}

const particles = Array.from({ length: 250 }, () => ({
  factor: MathUtils.randInt(20, 100),
  speed: MathUtils.randFloat(0.01, 1),
  xFactor: MathUtils.randFloatSpread(80),
  yFactor: MathUtils.randFloatSpread(40),
  zFactor: MathUtils.randFloatSpread(40)
}))


const colorSelect = () => {
  const colors = [
    "#95eb00",
    "#f66754",
    "#268AFF"]
  const n = MathUtils.randInt(0, 2)
  return colors[n]
}

const Bubbles: any = () => {
  return (
    particles.map((data, i) => (
      <Bubble key={i} {...data} />
    ))
  )
}

const Bubble = ({ factor, speed, xFactor, yFactor, zFactor }: Props) => {
  const ref: any = useCannon({ mass: 10 }, (body: CANNON.Body) => {
    body.addShape(new CANNON.Sphere(1))
  })
  useFrame((state) => {
    const t = factor + state.clock.elapsedTime * (speed / 4)
    ref.current.scale.setScalar(Math.max(1.5, Math.cos(t) * 5))
    ref.current.position.set(
      Math.cos(t) + Math.sin(t * 1) / 10 + xFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 1) * factor) / 10,
      Math.sin(t) + Math.cos(t * 2) / 10 + yFactor + Math.sin((t / 10) * factor) + (Math.cos(t * 2) * factor) / 10,
      Math.sin(t) + Math.cos(t * 2) / 10 + zFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 3) * factor) / 10
    )
  })
  return (
    <mesh ref={ref} castShadow receiveShadow>
      <sphereGeometry attach="geometry" args={[1, 32, 32]} />
      <meshPhysicalMaterial attach="material" color={colorSelect()} />
    </mesh>
  )
}

const MainBackground = () => {
  const Wrapper = styled.div`
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000;
    filter: blur(80px);
    ${sp`
      filter: blur(6rem);
    `}
  `
  return (
    <Wrapper>
      <Canvas camera={{ position: [0, 40, -15] }}>
        <ambientLight intensity={0.5} />
        <spotLight
          intensity={0.8}
          position={[30, 50, 30]}
          angle={0.3}
          penumbra={1}
          castShadow
        />
        <spotLight
          intensity={0.8}
          position={[-30, -50, 30]}
          angle={0.3}
          penumbra={1}
          castShadow
        />
        <spotLight
          intensity={0.8}
          position={[0, 40, -100]}
          angle={0.3}
          penumbra={1}
          castShadow
        />
        <Provider>
          <Bubbles />
        </Provider>
      </Canvas>
    </Wrapper>
  )
}

export default MainBackground
